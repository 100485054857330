import http from '@amit/composables/src/useHttp';
import { useApi } from '@amit/composables';

export const fetchSkills = () => useApi(http.get('/content/skills'));

export const fetchCourse = course => useApi(http.get(`/courses/${course}`));

// export const fetchCourses = () => useApi(http.get('/courses'));
export const fetchCourses = ({
  onlyPublished = false,
  onlyMyCourses = false,
} = {}) => {
  return useApi(
    http.get('/courses', {
      params: {
        ...(onlyPublished ? { published: onlyPublished } : {}),
        onlyMyCourses,
      },
    }),
  );
};

export const createCourse = course => {
  return useApi(http.post('/courses', course));
};

export const updateCourse = (courseId, course) => {
  return useApi(http.put(`/courses/${courseId} `, course));
};

export const fetchExercises = (course, lesson) =>
  useApi(http.get(`courses/${course}/lessons/${lesson}/exercises`));

export const fetchLesson = (course, lesson) =>
  useApi(http.get(`/courses/${course}/lessons/${lesson}`));

export const createLesson = (course: string, lesson: object) => {
  return useApi(http.post(`/courses/${course}/lessons`, lesson));
};

export const updateLesson = (course, lesson, data) =>
  useApi(http.put(`/courses/${course}/lessons/${lesson}`, data));

export const fetchPages = (course: string, lesson: string) => {
  return useApi(http.get(`/courses/${course}/lessons/${lesson}/pages`));
};

export const uploadFile = (
  formData: FormData,
  courseId: any,
  userId: string,
) => {
  return useApi(
    http.post(`/files/user/${userId}/course/${courseId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
  );
};

export const addLink = (file: any, courseId: any, userId: string) => {
  return useApi(
    http.post(`/files/user/${userId}/course/${courseId}/link`, file),
  );
};

export const listFiles = (
  course: string | any,
  userId: string | null = 'null',
) => {
  return useApi(http.get('/files', { params: { course, userId } }));
};

export const listCourseFiles = (course: string) => {
  return useApi(http.get(`/files/course/${course}`));
};

export const deleteFile = (fileId: string) => {
  return useApi(http.delete(`/files/${fileId}`));
};

export const asyncDownloadFile = (fileId: string) => {
  return useApi(http.get(`/files/${fileId}`));
};
